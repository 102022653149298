@charset "UTF-8";
@import (reference) "_includes/_vars.less";
@import (reference) "_includes/_mixins.less";
@import "ground.less";
@import "aos.css";

/*---------------------------------------*\
   App Style
\*---------------------------------------*/
html {
  font-size: 13px;
}

/*---------------------------------------*\
   Header
\*---------------------------------------*/

.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  color: @white;
  z-index: 100;
  background: rgba(255, 255, 255, 0.65);
  .transition-default();
  &__rhalf {
    @media(min-width: 1920px) {
      display: none;
    }
    width: 50%;
    &:extend(.absolute_align all);
    background-color: rgba(8,119,180, 0.65);
    margin: 0;
    left: unset !important;
    @media(max-width: 992px){
      background-color: transparent;
    }
  }
  &__in {
    &:extend(.container all);
  }
  &__logo {
    line-height: 1;
    padding: @offset 0;
    width: 120px;
    float: left;
    text-align: center;
    transform: scale(1);
    transform-origin: center left;
    a {
      &:extend(.clear);
    }
    img {
      &:extend(.img_responsive all);
      max-width: 120 / @bs * 1rem;
    }
  }
  &__menu {
    display: none;
    margin-bottom: @offset;
    .fs_px(15);
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: @secondary;
    text-align: center;
    &:extend(.clear);
    ul {
      li {
        display: block !important;
        width: 100%;
        opacity: 0;
        a {
          width: 100%;
          padding: @offset @offset @offset 0;
          opacity: 1;
          color: @secondary;
          .hover({
            opacity: 0.6;
          });
        }
      }
    }
    body.screen_sm &, body.screen_md &, body.screen_lg & {
      width: auto;
      display: block !important;
      position: absolute;
      right: 0;
      top: @offset;
      ul {
        li {
          opacity: 1 !important;
          display: inline-block !important;
          width: auto;
          a {
            padding: @offset-md;
            color: @white;
          }
        }
      }
    }
  }

  &__toggle {
    cursor: pointer;
    width: 38px;
    height: 35px;
    margin-right: 5px;
    position: absolute;
    top: @offset-md;
    right: @offset;
    &:extend(.not_selectable all);
    .transition-default();
    div {
      height: 3px;
      margin: 9px auto;
      width: 100%;
      background: @primary;
      .transition-default();
      &:last-child {
        margin-bottom: 0;
      }
    }
    &.on {
      div {
        background: @secondary;
      }
      .one {
        transform: rotate(135deg);
        margin-top: 20px;
      }
      .two {
        opacity: 0;
      }
      .three {
        transform: rotate(-135deg);
        margin-top: -24px;
      }
    }
    body.screen_sm &, body.screen_md &, body.screen_lg & {
      display: none !important;
    }
  }
  .menu_active & {
    .bga(@white, 0.9);
  }
  .menu_active.screen_sm & {
    background: transparent !important;
  }
}

/*---------------------------------------*\
   General
\*---------------------------------------*/
.section {

  &-contact {
    padding: @offset-md 0;
  }

  &__header {
    margin: @offset-md auto;
    max-width: 85%;
    @media(min-width: @screen_desktop) {
      max-width: 65%;

    }
    text-align: center;
    &__icon {
      &:extend(.img_responsive all);
      max-width: 120px;
      margin-bottom: 0;
    }
    &__title {
      .fs(36);
      &:extend(.title1 all);
      color: @primary;
      margin-bottom: @offset;
    }
    &__subtitle {
      &:extend(.subtitle all);
    }
    &__desc {
      .fs(20);
      font-weight: 300;
      color: @secondary;
    }
  }
  &__content {
    line-height: (@bs + 10) / @bs * 1rem;
    padding: @offset-md 0;
  }
  &__cta {
    text-align: center;
    margin-top: @offset-md;
  }
}

/*---------------------------------------*\
   sections
\*---------------------------------------*/

.page_banner {
  width: 100%;
  max-width: 1920px;
  height: 100%;
  max-height: 1278px;
  overflow: hidden;
  padding: 0;
  object-fit: cover;
  color: @white;
  text-align: center;
  background-size: cover;
  background-position: center -25px;
  background-repeat: no-repeat;
  @media(min-width: 1920px) {
    margin: auto;
  }
  @media(max-width: 992px){
    background-size: inherit;

  }


  &__rhalf {
    width: 50%;
    &:extend(.absolute_align all);
    background-color: rgba(8,119,180, 0.65);
    margin: 0;
    left: unset !important;
    @media(max-width: 992px){
      width: 100%;
      left: 0!important;
    }
  }
  &__fieldset {
    border: 3px solid #ffffff;
    max-height: 300px;
    max-width: 580px;
    position: relative;
    left: -100px;
    @media(max-width: 555px){
      border: none;
    }
    @media(max-width: 992px){
      left: 0;
      right: 0;
    }
    &__outter {
      height: 100%;
      width: 97px;
      position: relative;
      float: left;
      background: rgba(255,255,255,0.65);
      @media(max-width: 555px){
        display: none;
      }
      span {
        color: @secondary;
        text-transform: uppercase;
        .ff_futura();
        .fs(19);
        font-weight: 500;
        position: absolute;
        top: 50%;
        white-space: nowrap;
        left: 0;
        right: 0;
        transform: rotate(-90deg);
      }
    }
    &__inner {
      height: 100%;
      width: 450px;
      float: left;
      text-align: left;
      position: relative;
      padding: @offset-lg @offset-md;
      h1 {
        .fs(42);
        font-weight: 300;
        letter-spacing: 2.84px;
        strong {
          font-weight: 700;
          letter-spacing: 3.33px;
          display: inherit;
        }
      }
    }

  }
  @media(max-width: 992px){

  }
}
.vcentercontent {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}

.page_about {
  &__lhalf {
    width: 50%;
    &:extend(.absolute_align all);
    background-color: #fff;
    margin: 0;
    top:-25px;

  }
  &__row {
    &:extend(.grid_row all);
    margin: @offset-xl auto;
  }
  &__colcontent {
    &:extend(.grid_col all);
    width: 48%; // 8% 2% 40% 10% 40%
    margin-right: 2%;
    @media(max-width: @screen_tablet) {
      width:100%;
    }
  }
  &__colimage {
    &:extend(.grid_col all);
    width: 40%; // 8% 2% 40% 10% 40%
    @media(max-width: @screen_tablet) {
      width:100%;
    }
  }
  &__coltitle {
    &:extend(.grid_col all);
    width: 8%; // 8% 2% 40% 10% 40%
    margin-right:2%;
    @media(max-width: @screen_tablet) {
      width:100%;
      margin-right:0;
    }
  }
  &__title {
    @media(max-width: @screen_tablet) {
      max-width: 250px;
      margin-bottom: @offset;
    }
  }
  &__content {
    max-width: 100%;
    h2 {
      &:extend(.title2 all);

      color: @primary;
      span {
        display: inline;
        font-weight: 900;
        letter-spacing: 2.5px;
      }
    }
    strong {
      &:extend(.perex all);
      letter-spacing: 1.25px;

    }
    p {
      &:extend(.text all);

    }
  }
  &__image {
    img {
      &:extend(.img_responsive all);
      max-width: 450px;
    }
  }
  @media(max-width: @screen_tablet) {

  }
}

.page_features {
  background-color: #ebebeb;
  padding: @offset-lg 0;

  &__in {
    &:extend(.container all);
  }
  ul {
    .ul_reset();
    text-align: center;
    li {
      display: inline-block;
      margin-right: @offset-lg;
      &:last-child {
        margin-right: 0;
      }
      @media(max-width: @screen_tablet) {
        margin-right: 0;
        margin-bottom: 2rem;
      }
      img {
        &:extend(.img_responsive all);
        max-height: 63px;
      }
      span{
        display: block;
        padding-top: @offset;
        text-transform: uppercase;
        color: @secondary;
        letter-spacing: 0.94px;
        .fs(14);
      }

    }
  }
}

.page_vision {
  &__row {
    &:extend(.grid_row all);
    margin: @offset-xl auto;
  }
  &__col {
    &:extend(.grid_col all);
    &-title {
      width: 8%; // 8% 2% 40% 10% 40%
      margin-right:2%;
      @media(max-width: @screen_tablet) {
        width:100%;
        margin-right:0;
      }
    }
    &-image {
      width: 40%; // 8% 2% 40% 10% 40%
      margin-right: 2%;
      @media(max-width: @screen_tablet) {
        width:100%;
        margin-right: 0;
      }
    }
    &-content {
      width: 48%; // 8% 2% 40% 10% 40%
      @media(max-width: @screen_tablet) {
        width:100%;
      }
    }
  }
  &__title {
    @media(max-width: @screen_tablet) {
      max-width: 250px;
      margin-bottom: @offset;
    }
  }
  &__content {
    max-width: 100%;
    h2 {
      &:extend(.title2 all);

      color: @primary;
      span {
        display: inline;
        font-weight: 900;
        letter-spacing: 2.5px;
      }
    }
    strong {
      &:extend(.perex all);
      letter-spacing: 1.25px;

    }
    p {
      &:extend(.text all);
    }

  }
  &__image {
    img {
      &:extend(.img_responsive all);
      max-width: 450px;
    }
  }

}


/*---------------------------------------*\
  Contact
\*---------------------------------------*/

.page_contact {
  background-image: linear-gradient(-24deg, #0b132d 0%, #282e4b 100%);
  padding: @offset-xl;
  @media(max-width: @screen_tablet) {
    padding: @offset;
  }
  &__row {
    &:extend(.grid_row all);
  }
  &__col {
    &:extend(.grid_col all);
    width: 50%;
    @media(max-width: @screen_tablet) {
      width:100%;
    }
  }
  &__wrap {
    box-shadow: 2px 2px 3px 1px rgba(0, 0, 0, 0.1);
    border: 3px solid #ffffff;
    padding: @offset-md @offset-lg;
    &__header {
      &:extend(.title1 all);
      font-weight: 900;
      color: @white;
    }

  }

  &__form {

  }
  &__info {
    max-width: 300px;
    margin: 0 auto;
    padding: 0;
    list-style: none;
    @media(max-width: @screen_tablet) {
      max-width: 230px;
    }
    li {
      margin: @offset 0;
      .item {
        .fs(18);
        color: @white;
        img {
          height: 40px;
          width: 70px;
          position: relative;
          top: 15px;
          padding-right: @offset-md;
        }

      }
    }
  }
  &__bgset {
    background-image: url("../files/img/contact.png");
    background-repeat: no-repeat;
    min-height: 410px;
    position: absolute;
    top: -20px;
    right: 0;
    width: 53%;
  }
  &__nav {
    max-width: 400px;
    margin: 50% auto 0;
    position: relative;
    bottom: 0;
    @media(max-width: @screen_tablet) {
      margin: @offset-lg auto 0;
      max-width: 330px;
    }

    &__logo {
      &:extend(.img_responsive all);
      max-width: 120px;
      @media(max-width: 379px){
        display: block;
        margin: @offset auto;
      }
    }
    &__menu {
      margin: 0;
      padding: 0;
      list-style: none;
      display: inline-block;
      position: relative;
      bottom:35px;
      padding-left: @offset-lg;
      li {
        display: inline-block;
        a {
          color: @white;
          text-transform: uppercase;

          padding-right: @offset-md;
        }
      }
      @media(max-width: 379px){
        display: block;
        text-align: center;
        bottom: unset;
        padding: 1rem 0;
        text-align: center;
      }
    }
  }
}

/*---------------------------------------*\
   Footer
\*---------------------------------------*/
.footer {
  clear: both;
  padding-top: @offset-md;
  .fs(14);
  font-weight: 100;
  text-align: left;
  color: @secondary;
  border-bottom: 18px solid @asure;
  &__arrow {
    a {
      max-width: 55px;
      margin: auto;
      display: block;
    }
    img {
      &:extend(.img_responsive all);
    }
  }
  &__wrap {
    display: flex;
    flex-direction: column;
    @media (min-width: @screen_tablet) {
      flex-direction: row;
    }
  }
  &__text {
    width: 100%;
    float: none;
    text-align: center;
    p {
      &.legal {
        text-align: justify;
        width: 100%;
        @media (min-width: @screen_tablet) {
          width: 50%;
        }
      }
    }
    .address {
      display: flex;
      flex-direction:row;
      width: 100%;
      @media (min-width: @screen_tablet) {
        width: 50%;
      }
      p {
        strong {
          color:#0876b3;
          font-weight:bold;
        }
        margin-left:1rem;
        text-align: left;
        display: flex;
        flex-direction: column;
      }
    }

    .copyright {
      margin: @offset;
    }
  }
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
}
@-webkit-keyframes bounce {
  0%, 100% {
    -webkit-transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-10px);
  }
}
@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}
.bounce {
  -webkit-animation-name: bounce;
  animation-name: bounce;
}

/*---------------------------------------*\
   Font sizes Media Query
\*---------------------------------------*/
@font_size_mobile: 13;
@font_size_tablet: 14;
@font_size_desktop: 15;
@font_size_large: 16;

/*---------------------------------------*\
   App Media Query
\*---------------------------------------*/

  #mobilesvg {
    display: block;
  }
  #desktopsvg {
    display: none;
  }
 @media (min-width: @screen_mobile) {
  html {
    font-size:unit(@font_size_mobile,px);
  }

}
@media (min-width: @screen_tablet) {
  html {
    font-size:unit(@font_size_tablet,px);
  }
  #mobilesvg {
    display: none;
  }
  #desktopsvg {
    display: block;
  }
}
@media (min-width: @screen_desktop) {
  html {
    font-size:unit(@font_size_desktop,px);
  }
}
@media (min-width: @screen_large) {
  html {
    font-size:unit(@font_size_large,px);
  }
}
